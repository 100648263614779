// Fake Window object for server-side rendering in ReactJS.NET
if (typeof window === 'undefined') {
    window = {
        // Fake requestAnimationFrame for useSprint
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        requestAnimationFrame: () => { },
        location: {
            search: ''
        }
    };
}
