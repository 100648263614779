const asyncRequestIdleCallback = async (callback) => {
    if (typeof window !== 'undefined' &&
        window.requestIdleCallback) {
        return await new Promise((resolve, reject) => window.requestIdleCallback(async () => {
            try {
                const result = callback();
                resolve(result);
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    else {
        return await callback();
    }
};
export default asyncRequestIdleCallback;
