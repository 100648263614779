import ReactDOM from 'react-dom';
import asyncRequestIdleCallback from '../../utils/asyncRequestIdleCallback';
import canUseDOM from '../../utils/serverSide/canUseDOM';
window.hydrationQueue = window.hydrationQueue || [];
export default class HydrationQueueManager {
    constructor() {
        this.renderedComponents = [];
        this.loadIfReady = () => {
            if (document.readyState !== 'complete')
                return;
            document.removeEventListener('readystatechange', this.loadIfReady);
            this.hydrate();
            window.hydrationQueue.push = this.pushFutureComponents; // Hydrate components after pageload
            return true;
        };
        this.pushFutureComponents = (item) => {
            this.hydrateComponent(item);
            return 0;
        };
        if (!canUseDOM)
            return;
        // Initial page load
        if (this.loadIfReady())
            return;
        document.addEventListener('readystatechange', this.loadIfReady, false);
    }
    async hydrate(hydrate) {
        // Step 1: Remove duplicate render/hydration scripts from the hydrationQueue
        window.hydrationQueue = window.hydrationQueue.filter((item) => {
            if (!item.initComponent)
                return true;
            if (window.hydrationQueue.find(existingItem => (existingItem.component &&
                existingItem.selector === item.selector // Duplicate selectors only need to be hydrated once
            ))) {
                console.warn(`Duplicate ${item.componentName} component in ${item.selector} - Hydration already initialized`);
                return false;
            }
            return true;
        });
        for (const item of window.hydrationQueue) {
            await this.hydrateComponent(item, hydrate);
            this.renderedComponents.push(item.selector);
        }
    }
    async hydrateComponent(item, hydrate) {
        if (this.renderedComponents.includes(item.selector)) {
            // console.log(item.selector, 'Already rendered on this page');
            return;
        }
        const root = document.querySelector(item.selector);
        if (!root) {
            console.warn(`${item.componentName} component in ${item.selector} is not in the current page`);
            return;
        }
        const isEmpty = !(root.innerHTML.trim());
        if (hydrate !== undefined && hydrate === isEmpty) {
            // console.log(item.selector, (hydrate) ? 'Cannot hydrate in an empty element' : 'Cannot render in an element with existing html');
            return;
        }
        // Step 2: Preload component script
        if (item.initComponent) {
            // Reduce input feedback blocking by using requestIdleCallback
            await asyncRequestIdleCallback(async () => {
                try {
                    if (!item.componentName) {
                        throw new Error('Missing componentName from HydrationQueue item');
                    }
                    const component = item.componentName
                        .split('.')
                        .reduce((parent, name) => parent[name], window);
                    // Some components are already included in the main.js
                    if (component.load) {
                        await component.load();
                    }
                    // Already loaded by another promise in the mean time
                    if (!item.initComponent) {
                        // console.log('preloaded component already loaded', item, component);
                        return;
                    }
                    item.component = item.initComponent();
                    delete item.initComponent;
                }
                catch (error) {
                    console.error('Error while preloading component:', error, item);
                }
            });
        }
        // Step 3: Render/hydrate component
        // Render in an empty element | Hydrate in an element with existing html
        const renderMethod = (isEmpty) ? ReactDOM.render : ReactDOM.hydrate;
        try {
            renderMethod(item.component, root);
        }
        catch (ex) {
            console.error(`Failed to hydrate ${item.componentName} component in ${item.selector}`, ex);
        }
    }
}
